import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';
import { Grid, Row, Col } from 'react-flexbox-grid';

import imgSEO from '../../assets/img/heroes/social2.jpg';
import SimilarCard from '../../components/similar.card';
import similar1 from '../../assets/img/services/thumbnail/seo.jpg';
import similar3 from '../../assets/img/services/thumbnail/contenu.jpg';
import similar2 from '../../assets/img/services/thumbnail/pub.jpg';
import similar4 from '../../assets/img/services/thumbnail/maintenance.jpg';
import SEO from '../../components/seo';
import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/social_influencer.svg';
import contentPic from '../../assets/img/illustrations/optimisation.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Gestion de contenu  | Agence digitale | MDS Digital Agency"
      description="MDS Digital Agency est une agence digitale spécialisée dans la gestion de contenu. Nous nous occupons de rédiger le contenu ainsi que rechercher le visuel pour l’illustrer."
      keywords="Gestion de contenu"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Optimisation de votre site internet</h2>
      <div className={pageStyle.intro_container} style={{ marginTop: '3em', marginBottom: '6em' }}>
        <h4 className={pageStyle.intro}>
          Posséder un site internet aux performances techniques parfaites.
        </h4>
      </div>
      <Grid style={{ marginBottom: '6em', textAlign: 'right' }}>
        <Row>
          <Col md={6} className={pageStyle.flex_v_align}>
            <div>
              <p>
                Votre site internet doit être entretenu, vérifié et mis à jour afin de toujours
                fonctionner de manière optimale et sécurisée. Vous éviterez ainsi que vos visiteurs
                soient confrontés à des problèmes de fonctionnement du site et vous vous prémunirez
                des attaques de hackers.
              </p>
              <p>
                MDS Digital Agency s’occupe de toutes les améliorations qui peuvent être mise en
                place suite à l’audit. Par exemple, envisager une migration vers des serveurs plus
                performants, debug, mettre en place de nouvelles fonctionnalités.
              </p>
            </div>
          </Col>
          <Col md={6} className={pageStyle.flex_v_align}>
            <img
              src={contentPic}
              alt=""
              style={{
                display: 'inline-block',
                margin: '30px',
                marginBottom: '15px'
              }}
            />
          </Col>
        </Row>
      </Grid>

      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="A la recherche d’une stratégie de référencement sur mesure ?"
        subtitle="Bénéficiez d’une plus grande visibilité sur le web grâce à notre forte expertise."
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Référencement naturel (SEO)"
            url="/services/referencement/naturel/"
            img={similar1}
          />
          <SimilarCard
            text="Référencement payant (SEA)"
            url="/services/referencement/payant/"
            img={similar2}
          />
          <SimilarCard
            text="Mise à jour de contenu"
            url="/services/mise-a-jour-contenu/"
            img={similar3}
          />
          <SimilarCard text="Maintenance" url="/services/maintenance/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
